import { Link } from 'gatsby';
import SEO from '../components/SEO';
import SiteLayout from '../layouts/SiteLayout';

const BookMe = () => (
  <SiteLayout>
    <SEO title="Software Development Consulting" />
    <h1>Consulting</h1>
    <p>
      Need help with building, launching or scaling your platform? Need help with any tasks related
      to software development ? A technical advisor? I’ll be your guy, and if it ain’t me, I work
      with a great variety of very dedicated people.
    </p>

    <h2>On-premise consulting</h2>

    <p>
      I work efficiently remotely for clients around the world from my laptop, but I am nevertheless
      transportable as long as you cover the transportation expenses.
    </p>

    <h2>Introduce me to your challenges...</h2>

    <p>
      I am curious to hear more about your challenges. Please{' '}
      <Link to="/contact">get in touch with me</Link>, and let’s see how I may help you.
    </p>
  </SiteLayout>
);

export default BookMe;
